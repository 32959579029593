export const heroData = {
  name: "Ghasem Olfat",
  title: "",
  description:
    "I'm a passionate Full Stack developer with professional experience in designing, developing, testing, and optimizing web applications. Proficient in C#, ASP.NET Core and MVC, ReactJs, MicroServices, SQL, NoSQL and Cloud Technologies.I am strongly interested in learning new technologies and implementing them in projects. I'm a self-motivated and hardworking individual who is always ready to learn new things and work in a team.",
  resumeLink: "#",
  lottieAnimationFile: "/lottie/hero.json",
};
export const seoData = {
  title: "Ghasem Olfat",
  description: heroData.description,
  author: "Ghasem Olfat",
  image: "https://avatars.githubusercontent.com/u/56147385?v=4",
  url: "https://olfat.dev",
  keywords: [
    "Ghasem Olfat",
    "Jason Olfat",
    "Portfolio",
    "Ghasem Olfat Portfolio",
  ],
};
export const socialLinks = [
  // {
  //   id: "tooltip0",
  //   title: "Email",
  //   desc: "Email me",
  //   class: "fa fa-envelope",
  //   link: "mailto:jason.olfat@gmail.com",
  // },
  {
    id: "tooltip1",
    title: "Linkedin",
    desc: "Linkedin",
    class: "fa fa-linkedin",
    link: "https://www.linkedin.com/in/ghasem-olfat/",
  },
  {
    id: "tooltip2",
    title: "Github",
    desc: "Github",
    class: "fa fa-github",
    link: "https://github.com/olfat-gh/",
  },
];

export const skillsSection = {
  title: "What Can I do?",
  data: [
    {
      lottieAnimationFile: "/lottie/skills.json",
      skills: [
        "Designing, developing, testing, and deploying scalable applications and backend services using C# and .NET technologies",
        "Building SPA web applications with React.js",
        "Building RESTful APIs in ASP.Net Core & Node.js",
      ],
      softwareSkills: [
        {
          skillName: "C#",
          fileName: "c-sharp.svg",
        },
        {
          skillName: "ASP.NET Core, MVC",
          fileName: "aspdotnet.svg",
        },
        {
          skillName: "JavaScript",
          fileName: "javascript.svg",
        },
        {
          skillName: "ReactJs",
          fileName: "react.svg",
        },
        {
          skillName: "NodeJs",
          fileName: "nodejs.svg",
        },
        {
          skillName: "AWS",
          fileName: "aws.svg",
        },
        {
          skillName: "Azure",
          fileName: "microsoft-azure.svg",
        },
        {
          skillName: "Docker",
          fileName: "docker.svg",
        },
        {
          skillName: "SQL Databases",
          fileName: "sql-database-generic.svg",
        },
      ],
    },
  ],
};
export const SkillBars = [
  {
    Stack: "C# / ASP.NET ",
    progressPercentage: "100",
  },
  {
    Stack: "ReactJs / JavaScript / TypeScript / HTML / CSS",
    progressPercentage: "90",
  },
  {
    Stack: "SQL / Database",
    progressPercentage: "90",
  },
  {
    Stack: "Azure / AWS / Docker",
    progressPercentage: "85",
  },
];

export const experienceList = [
  {
    role: "Full Stack Developer",
    company: "Nuvei Technologies Corp",
    companyLink: "https://nuvei.com/",
    companyLogo: "nuvei_logo.jpg",
    companyColor: "success",
    date: "May 2021 - Sep 2023",
    desc: "Backend and frontend developer for a variety of web apps, and REST APIs for payment processing, merchant management,etc. Leveraged ASP.NET Core, React, Azure for efficient development.",
  },
  {
    role: "Senior Software Developer",
    company: "Daroo Pardazan Novin",
    companyLink: "https://darupardazan.com/",
    companyLogo: "darupardazan_novin_logo.jpg",
    companyColor: "primary",
    date: "Apr 2015 - Aug 2019",
    desc: "Designed, implemented, and developed a comprehensive pharmacy management system based on .Net as a startup with over 2000 customers.",
  },
  {
    role: "Full Stack Developer",
    company: "Dadeh Pardaz PSoft",
    companyLogo: "darupardazan_novin_logo.jpg",
    companyColor: "warning",
    date: "Jun 2012 - Mar 2015",
    desc: "Involved in all phases of SDLC like requirement analysis and implementation to develop diverse web applications.Utilized C#, ASP.NET Core, MVC and different components like Telerik UI, Kendo UI, DevExpress, Syncfusion, Stimulsoft Reports, and Crystal Report.",
  },
];

export const educationList = [
  {
    name: "Concordia University",
    title: "Master of Engineering Information System Security",
    duration: "2019 - 2021",
    desc: "",
    grade: "GPA 3.88 / 4.3",
    logo: "concordia.png",
    link: "https://www.concordia.ca/",
  },
  {
    name: "Razi University",
    title: "Bachelor of Science Computer Software Engineering",
    duration: "2008 - 2012",
    desc: "",
    grade: "Grade A",
    logo: "razi-university-logo.png",
    link: "https://en.razi.ac.ir/",
  },
];
